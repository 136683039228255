/** @format */

@tailwind base;
@tailwind components;
@tailwind utilities;

/* * {
  border: none;
  margin: 0;
  padding: 0;
} */

body {
  min-height: 100vh;
}
